<div class="purchace-requestTab">
  <mat-tab-group [backgroundColor]="'primary'" animationDuration="0ms" [(selectedIndex)]="demo1TabIndex"
      (selectedTabChange)="auditTabChanged($event)">
      <mat-tab>
        <ng-template mat-tab-label>Audit </ng-template>
        <ng-template matTabContent>
          <mat-card class="dashboard-card">
            <mat-card-content class="dashboard-card-content p-0">
              <form #auditform="ngForm" (ngSubmit)="submitData(auditform)">
                <div class="row mt-3 savemargin">
                    <div class="col-md-0 ml-2 mb-2 mr-2 ng-star-inserted">
                        <button mat-raised-button class="savebutton addBtn" type="submit" [disabled] = "selectedApprovers.length === 0 || !AuditCompanyNamec"
                            >Save</button>
                    </div>
                    <div class="col-md-0 ml-1">
                      <button type="button" mat-raised-button class="attachfile attachFileBtn ml-1" color="secondary"
                      (click)="openfileuploadmodel1(fileuploadmodel1)"   >
                          Attach File&nbsp; <mat-icon
                              class="attachFileIcon">file_upload</mat-icon></button>
                  </div>
                </div>
                <div class="row mt-1">
                    <!-- <div class="col-md-4">
                        <label>Company Name<span class="mandetory">*</span></label>
                        <mat-form-field class="example-full-width145 matformfieldstyles1 mt-1"  appearance="none">
                          <input type="text"  aria-label="Number" matInput
                            class="inputdropdown1" [matAutocomplete]="auto4" placeholder="Enter Company Name" name="AuditCompanyNamec" [(ngModel)]="AuditCompanyNamec"
                            (keyup)="onCompanySelectionCL($event)" (click)="openDropdown()"/>
                          <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete" (optionSelected)="selectedcompanyaudit()">
                            <mat-option *ngFor="let item of companyData11" [value]="item.company_name">
                              {{ item.company_name}}</mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                    </div> -->
                    <div class="col-md-4">
                      <label>Company Name<span class="mandetory">*</span></label>
                      <mat-form-field class="example-full-width145 matformfieldstyles1 mt-1" appearance="none">
                          <input type="text" aria-label="Number" matInput
                              class="inputdropdown1" [matAutocomplete]="auto4" placeholder="Enter Company Name" name="AuditCompanyNamec" [(ngModel)]="AuditCompanyNamec"
                              (keyup)="onCompanySelectionCL($event)" (click)="openDropdown()"/>
                          <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete" (optionSelected)="selectedcompanyaudit()">
                              <ng-container *ngIf="companyData11.length > 0;else noRecords">
                              <mat-option *ngFor="let item of companyData11" [value]="item.company_name">
                                  {{ item.company_name }}
                              </mat-option>
                              </ng-container>
                              <ng-template #noRecords>
                                <mat-option>No Records Found</mat-option>
                              </ng-template>
                          </mat-autocomplete>
                      </mat-form-field>
                  </div>
                    <!-- <div class="col-md-6">
                      <label>Audit Name<span class="mandetory">*</span></label>
                      <input type="text" class="inputstyles" placeholder="Audit Name" name="AuditNamec" [(ngModel)]="AuditNamec" readonly>
                    </div> -->
                    <div class="col-md-6">
                      <label>Approvers<span class="mandetory">*</span></label>
                      <mat-form-field appearance="outline" class="w-full mrgtop">
                        <mat-chip-list #chipList> 
                            <mat-chip *ngFor="let approver of selectedApprovers" [selectable]="selectable"
                            [removable]="removable" (removed)="remove(approver)">
                            {{approver}}
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                            <input placeholder="Search Approvers..." name="menuControl" #fruitInput [formControl]="myControl"
                            [matAutocomplete]="ApproversAuto" [matChipInputFor]="chipList"  [(ngModel)]="auditApproverslist"
                              (matChipInputTokenEnd)="checkAndAdd($event)"    (keyup)="getApprovers($event.target.value)" required> 
                        </mat-chip-list>
                        <mat-autocomplete #ApproversAuto="matAutocomplete" (optionSelected)="selected($event)">
                          <!-- <ng-container *ngIf="myControl.touched && myControl.value"> -->
                            <ng-container *ngIf="(filteredOptions | async)?.length; else noUsers">
                          <mat-option *ngFor="let option of filteredOptions | async" [value]="option.username">
                            <span> {{option.username}}</span> |
                            <small> {{option.email}}</small>
                          </mat-option>
                          </ng-container>
                            <ng-template #noUsers>
                                <mat-option>
                                    No users found.
                                </mat-option>
                            </ng-template>
                            
                        </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    
                </div>
            </form>
              </mat-card-content>
              </mat-card>
        </ng-template>
      </mat-tab>
      <mat-tab>
          <ng-template mat-tab-label>List </ng-template>
          <ng-template matTabContent>
            <mat-card class="dashboard-card plant">
              <mat-card-content class="dashboard-card-content p-0">
                <ng-container>
                  <div class="row mt-3">
                    <div class="col-lg-3">
                      <mat-form-field class="fieldwidth" appearance="fill">
                        <mat-label>Select Company</mat-label>
                        <input matInput [matAutocomplete]="companyNameAuto" placeholder="Enter Company Name" name="AuditCompanyName" [(ngModel)]="AuditCompanyName"
                        (keyup)="onCompanySelection($event)" (click)="openDropdownlst()" (ngModelChange)="onCompanyOptionSelected()">
                        <mat-autocomplete #companyNameAuto="matAutocomplete">
                          <ng-container *ngIf="companyData1.length > 0;else noRecords">
                          <mat-option *ngFor="let item of companyData1" [value]="item.company_name">
                            {{ item.company_name}}</mat-option>
                            </ng-container>
                            <ng-template #noRecords>
                              <mat-option>No Records Found</mat-option>
                            </ng-template>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="fieldwidth" appearance="fill">
                          <mat-label>Audit Number</mat-label>
                          <input matInput [matAutocomplete]="auto" placeholder="Enter Audit Number" name="auditNamelist" [(ngModel)]="auditNamelist"
                          (keyup)="onAuditlistSelection()"  (ngModelChange)="onAuditlistOptionSelected()"
                            [disabled]="!AuditCompanyName">
                          <mat-autocomplete #auto="matAutocomplete" >
                            <mat-option *ngFor="let item of auditDashboardlist" [value]="item.auditName">
                              {{ item.auditName }}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                      <mat-form-field appearance="fill" class="fieldwidth">
                        <mat-label>Filter by Status</mat-label>
                        <mat-select (selectionChange)="onStatusChange($event)" [(value)]="selectedOption"
                        [disabled]="!AuditCompanyName">
                          <mat-option value="ALL">ALL</mat-option>
                          <mat-option value="PENDING">PENDING</mat-option>
                          <mat-option value="COMPLETED">COMPLETED</mat-option>
                          <mat-option value="APPROVED">APPROVED</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-3 mt-2 mb-3 text-right">
                      <button mat-icon-button value="Refresh" matTooltip="Refresh" (click)="getAuditDashboardData1()"
                          class="custom-icon-button ml-3">
                          <span class="material-icons">
                              refresh
                          </span>
                      </button>
                  </div>                   
                    <div class="col-12 col-md-12">
                      <div class="table-responsive mat-table-wrapper">
                        <div class="example-table-container  table-container" [ngClass]="{'auditTable': totalRecordsdashboard > 5}">
                          <table mat-table [dataSource]="auditDashboardData">
                            <ng-container matColumnDef="sno">
                              <th mat-header-cell *matHeaderCellDef class="column-sno">
                                S.NO
                              </th>
                              <td mat-cell *matCellDef="let row;let i=index;" class="column-sno text-center">
                                {{ calculateSerialNumber(i) }}
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="date">
                              <th mat-header-cell *matHeaderCellDef class="column-date">
                                Document Created On
                              </th>
                              <td mat-cell *matCellDef="let row; let i=index;" class="column-dat text-center">
                                {{row.created_date | date:'d MMMM yyyy' }}
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="auditname">
                              <th mat-header-cell *matHeaderCellDef class="column-auditname">
                                Audit Number
                              </th>
                              <td mat-cell *matCellDef="let row" class="column-auditname text-center">
                                {{ row.auditName }}
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="status">
                              <th mat-header-cell *matHeaderCellDef class="column-status">
                                Status
                              </th>
                              <td mat-cell *matCellDef="let row" class="column-status text-center">
                                {{ row.status }}
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="action">
        
                              <th mat-header-cell *matHeaderCellDef class="column-action">
                                Actions
                              </th>
                              <td mat-cell *matCellDef="let row; let i = index;" class="column-action">
                                <!-- <div class="matCellApprove" *ngIf="row.status === 'COMPLETED'">
                                  <button mat-raised-button class="updt_btn" color="primary"
                                    (click)="openDialog(row,i,'APPROVE')" [disabled]="!isApprover(row)">
                                    Approve
                                  </button>
        
                                  <button mat-raised-button class="rjt_btn" color="warn" (click)="openDialog(row,i,'REJECT')"
                                    [disabled]="!isApprover(row)">
                                    Reject
                                  </button>
                                </div> -->
        
                                <div *ngIf="row.status === 'PENDING'">
                                  <mat-chip class="pendingChip"><mat-icon class="pendingIcon">pending_actions</mat-icon>
                                    Document Pending</mat-chip>
                                </div>
                                <div *ngIf="row.status === 'APPROVED'">
                                  <mat-chip class="approvedChip"><mat-icon class="approvedIcon">check_circle</mat-icon>
                                    Document Approved on {{row.updated_date| date:'d MMMM'}}</mat-chip>
        
                                </div>
        
                                <div *ngIf="row.status === 'REJECTED'">
        
                                  <mat-chip class="approvedChip"> {{row.reasonData[0]['reason']}}</mat-chip>
        
                                </div>
                              </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                          </table>
                        </div>
                        <div class="text-center noDataFound" *ngIf="noDatalist && !AuditCompanyName">
                          <h4 class="text-secondary m-3" >
                           Please  Select the Company Name and Audit Details 
                          </h4>
                        </div>
                        <div *ngIf="noDatalist && AuditCompanyName" class="text-center noDataFound" >
                          <h4 class="text-secondary m-3" *ngIf="!noDatalist" >
                            Loading...
                          </h4>
                          <h4 class="text-secondary m-3" *ngIf="noDatalist">
                            No Records Found
                          </h4>
                        </div>
                        <div *ngIf="!noDatalist && totalRecordsdashboard > 5" >
                        <mat-paginator #paginator  [length]="totalRecordsdashboard" [pageIndex]="(pageIndexAuditlist-1)"  [pageSize]="pageSizeAuditlist" [pageSizeOptions]="[5, 10, 50, 100, 500, 1000]"
                          (page)="onpageeventdashboard($event)"  >
                        </mat-paginator>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </mat-card-content>
            </mat-card>
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>Dashboard</ng-template>
          <ng-template matTabContent>
            <mat-card class="dashboard-card plant">
              <mat-card-content class="dashboard-card-content PlantData p-0">
                <div class="row plantWiseData ">
                  <div class="col-md-3 range">
                    <label>Enter Date Range<span class="text-danger">*</span></label>
                    <!-- <input type="text" onkeydown="return false;" placeholder="Enter Date Range" [showDropdowns]="true"  class="mat-form-field daterangepicker" 
                    [locale]="{applyLabel: 'ok', format: 'DD-MM-YYYY'}" ngxDaterangepickerMd startKey="start" endKey="end" 
                    [(ngModel)]="model" (datesUpdated)="datesUpdated($event)" alwaysShowCalendars="true" 
                    [autoApply]="true" [maxDate]="currentDate"  [ngx-daterangepicker-material]="pickerOptions">              -->
                    <input type="text" onkeydown="return false;" placeholder="Enter Date Range" [showDropdowns]="true" class="mat-form-field daterangepicker" 
       [locale]="{applyLabel: 'ok', format: 'DD-MM-YYYY'}" ngxDaterangepickerMd startKey="start" endKey="end" 
       [(ngModel)]="model" (datesUpdated)="datesUpdated($event)" alwaysShowCalendars="true" 
       [autoApply]="true" [maxDate]="currentDate" [ngx-daterangepicker-material]="pickerOptions">

                    <!-- <mat-form-field class="matField field" appearance="fill">
                      <mat-label>Enter a date range</mat-label>
                      <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="Start date" [max]="endDate" (dateChange)="onStartDateChange($event)" [(ngModel)]="startDate">
                        <input matEndDate placeholder="End date" [max]="today" (dateChange)="onEndDateChange($event)" [(ngModel)]="endDate">
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>                     -->
                    <!-- <mat-form-field class="matField field" appearance="fill">
                      <mat-label>Enter a date range</mat-label>
                      <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="Start date" dateFormat="yyyy/mm/dd" (dateChange)="onStartDateChange($event)"
                          [(ngModel)]="startDate">
                        <input matEndDate placeholder="End date" dateFormat="yyyy/mm/dd" (dateChange)="onEndDateChange($event)"
                          [(ngModel)]="endDate">
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field> -->
                  </div>
                  <div class="col-md-3 mt-3" >
                    <label>Company Name</label>
                    <mat-form-field class="selectC" appearance="outline" style="font-size:12px; width:100%">
                      <mat-select 
                        placeholder="Selected Company List"
                        [(ngModel)]="selecteddashboardCompanyName"
                        #selectdmr multiple
                      >
                      <div class="select-all">
                        <mat-checkbox [(ngModel)]="allSelecteddmr"
                        [ngModelOptions]="{standalone: true}"
                        (change)="toggleAllSelectiondmr()">Select All</mat-checkbox>
                    </div>
                        <mat-option (click)="optionClickdmr()"
                        *ngFor="let item of companyData2" [value]="item.company_name"
                          >{{ item.company_name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <!-- <div class="col-md-3">
                    <label>Company Name</label>
                    <mat-form-field class="matformfieldstyles" appearance="none">
                      <input matInput class="inputdropdown" [matAutocomplete]="companyAuto" placeholder="Enter Company Name"
                       [(ngModel)]="selecteddashboardCompanyName"    (keyup)="onCompanySelectionD($event)" (ngModelChange)="onCompanyOptionSelectedD()">
                      <mat-autocomplete #companyAuto="matAutocomplete" >
                        <mat-option *ngFor="let item of companyData2" [value]="item.company_name">{{ item.company_name
                        }}</mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
    
                  </div> -->
                  <div class="col-md-3">
                    <label>Audit Number</label>
                    <mat-form-field class="matformfieldstyles" appearance="none">
                      <!-- <mat-label>Audit Name</mat-label> -->
                      <input matInput class="inputdropdown" [matAutocomplete]="autoD" placeholder="Enter Audit Number" [(ngModel)]="auditNameD"
                      (keyup)="onAuditlistSelectionD()" (click)="openDropdowndashaudit()" (ngModelChange)="onAuditlistOptionSelectedD()"
                        [disabled]="selecteddashboardCompanyName.length == 0">
                      <mat-autocomplete #autoD="matAutocomplete">
                        <mat-option *ngFor="let item of auditDData" [value]="item.auditName">
                          {{ item.auditName }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3 mt-4 text-right">
                    <button mat-icon-button value="Refresh" matTooltip="Refresh" (click)="getPlantWiseData1()"
                        class="custom-icon-button ml-3">
                        <span class="material-icons">
                            refresh
                        </span>
                    </button>
                </div>       
                </div>
                <div class="table-responsive mat-table-wrapper mt-3">
                  <div class="example-table-container dashboardht">
                    <div class="table-container" [ngClass]="{'auditTable': totalRecordDashboarddata > 5}">
    
                      <table mat-table [dataSource]="plantWiseData">
                        <ng-container matColumnDef="sno">
                          <th mat-header-cell *matHeaderCellDef  style="width: 6%">
                            S.NO
                          </th>
                          <td mat-cell *matCellDef="let row; let i=index;" class="text-center">
                            {{ calculateSerialNumberD(i) }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="created_date">
                          <th mat-header-cell *matHeaderCellDef>
                            Date
                          </th>
                          <td mat-cell *matCellDef="let row"  class="text-center">
                            {{ row.created_date | date:'d MMMM yyyy' }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="companyId">
                          <th mat-header-cell *matHeaderCellDef>
                            Company Code
                          </th>
                          <td mat-cell *matCellDef="let row"  class="text-center">
                            {{ row.companyId }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="companyName">
                          <th mat-header-cell *matHeaderCellDef>
                            Company Name
                          </th>
                          <td mat-cell *matCellDef="let row" class="text-left">
                            {{ row.companyName }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="auditName">
                          <th mat-header-cell *matHeaderCellDef>
                            Audit Number
                          </th>
                          <td mat-cell *matCellDef="let row" class="text-center">
                            {{ row.auditName }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="status">
                          <th mat-header-cell *matHeaderCellDef>
                            Status
                          </th>
                          <td mat-cell *matCellDef="let row"  class="text-center">
                            {{ row.status }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="totalMaterialsCount">
                          <th mat-header-cell *matHeaderCellDef>
                            No of Materials
                          </th>
                          <td mat-cell *matCellDef="let row"  class="text-center">
                            {{ row.totalMaterialsCount }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="countedMaterials">
                          <th mat-header-cell *matHeaderCellDef>
                            Counted Materials
                          </th>
                          <td mat-cell *matCellDef="let row"  class="text-center">
                            {{ row.totalCountedMaterials }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="excessCount">
                          <th mat-header-cell *matHeaderCellDef>
                            Excess Count
                          </th>
                          <td mat-cell *matCellDef="let row"  class="text-center">
                            {{ row.totalExcessMaterialsCount }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="shortageCount">
                          <th mat-header-cell *matHeaderCellDef>
                            Shortage Count
                          </th>
                          <td mat-cell *matCellDef="let row"  class="text-center">
                            {{ row.totalShortageMaterialsCount }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="percentageOfCount">
                          <th mat-header-cell *matHeaderCellDef>
                            Percentage Of Count
                          </th>
                          <td mat-cell *matCellDef="let row"  class="text-center">
                            {{ row.percentage }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="unitPrice">
                          <th mat-header-cell *matHeaderCellDef>
                            Unit Price
                          </th>
                          <td mat-cell *matCellDef="let row"  class="text-center">
                            {{ row.unitValue | number}}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="totalValue">
                          <th mat-header-cell *matHeaderCellDef>
                            Total Value
                          </th>
                          <td mat-cell *matCellDef="let row"  class="text-right">
                            {{ row.totalValue | number}}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="countedValue">
                          <th mat-header-cell *matHeaderCellDef>
                            Counted Value
                          </th>
                          <td mat-cell *matCellDef="let row"  class="text-right">
                            {{ row.countedValue | number}}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="shortageValue">
                          <th mat-header-cell *matHeaderCellDef>
                            Shortage Value
                          </th>
                          <td mat-cell *matCellDef="let row" class="text-right">
                            {{ row.shortageValue | number }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="excessValue">
                          <th mat-header-cell *matHeaderCellDef>
                            Excess Value
                          </th>
                          <td mat-cell *matCellDef="let row" class="text-right">
                            {{ row.excessValue | number}}
                          </td>
                        </ng-container>
    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>
                      </table>
                      <div class="text-center noDataFound"  *ngIf="noDatadashboard">
                        <h4 class="text-secondary m-3">
                          No Records Found
                        </h4>
                      </div>
                    </div>

                  </div>
                 
                  
                    <mat-paginator #Dpaginator *ngIf="!noDatadashboard && totalRecordDashboarddata > 5" [length]="totalRecordDashboarddata "[pageIndex]="(pageIndexAuditlistDashboard-1)"  [pageSize]="pageSizeAuditlistDashboard" [pageSizeOptions]="[5, 10, 50, 100, 500, 1000]"
                      (page)="onpageeventD($event)">
                    </mat-paginator>
                </div>
              </mat-card-content>
            </mat-card>
              </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>Report</ng-template>
          <ng-template matTabContent>
            <mat-card class="dashboard-card plant">
              <mat-card-content class="dashboard-card-content p-0">
                <div class="row auditStockFields mt-3">
                  <div class="col-md-3">
                    <mat-form-field class="fieldwidth" appearance="fill">
                      <mat-label>Select Company</mat-label>
                      <input matInput [matAutocomplete]="companyAuditS" placeholder="Enter Company Name" name="arcompanyName" [(ngModel)]="arcompanyName"
                      (keyup)="onCompanySelectionR($event)" (click)="openDropdownreport()" (ngModelChange)="onCompanyOptionSelectedR()">
                      <mat-autocomplete #companyAuditS="matAutocomplete">
                        <ng-container *ngIf="companyData3.length > 0;else noRecords">
                        <mat-option *ngFor="let item of companyData3" [value]="item.company_name">{{ item.company_name
                        }}</mat-option>
                        </ng-container>
                        <ng-template #noRecords>
                          <mat-option>No Records Found</mat-option>
                        </ng-template>
                      </mat-autocomplete>
                    </mat-form-field>
    
                  </div>
                  <div class="col-md-3">
                    <mat-form-field class="matField field" appearance="fill">
                      <mat-label>Audit Number</mat-label>
                      <input matInput [matAutocomplete]="autoR" placeholder="Enter Audit Number" name="auditNameR" [(ngModel)]="auditNameR"
                      (keyup)="onAuditlistSelectionR()"  (ngModelChange)="onAuditlistOptionSelectedR()"
                        [disabled]="!arcompanyName">
                      <mat-autocomplete #autoR="matAutocomplete">
                        <mat-option *ngFor="let item of auditDashboardlistR" [value]="item.auditName">
                          {{ item.auditName }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div class="col-md-2 col-4 dowloadFiles">
                    <i class="fa fa-file-pdf" (click)="downLoadPdf()" matTooltip="PDF"
                      style="font-size: 28px; color: #bc1919; margin:10px"></i>
                    <i class="fa fa-file-excel" (click)="downLoadExcel()" matTooltip="Excel"
                      style="font-size: 28px; color: green; margin:10px"></i>
                  </div>
                  <div class="col-md-4 col-2 mt-2 mb-3 text-right">
                    <div class="matCellApprove1">
                      <h2 *ngIf="auditNameR && auditStatus1 !== 'COMPLETED'" class="mr-5 mb-0 mt-2 auditstatus0">
                        Audit Status: 
                        <span class="auditst" [ngStyle]="{
                          'color': auditStatus1 === 'REJECTED' ? '#ff5722' : 
                                    auditStatus1 === 'APPROVED' ? '#62beda' : 
                                    '#000'
                        }">{{auditStatus1 | titlecase}}</span>
                      </h2>
                      
                      <button mat-raised-button class="updt_btn" color="primary"
                        (click)="openDialog1(approvemodel)" *ngIf="auditNameR && auditStatus1 == 'COMPLETED'">
                        Approve
                      </button>

                      <button mat-raised-button class="rjt_btn mr-3" color="warn" (click)="openDialog(rejectmodel)"
                      *ngIf="auditNameR && auditStatus1 == 'COMPLETED'">
                        Reject
                      </button>
                      <button mat-icon-button value="Refresh" matTooltip="Refresh" (click)="onAuditNameSelect1()"
                        class="custom-icon-button ml-3">
                        <span class="material-icons">
                            refresh
                        </span>
                    </button>
                    </div>
                    
                </div> 
                </div>
                <div class="table-responsive mat-table-wrapper">
                  <div class="example-table-container">
                    <div class="table-container" [ngClass]="{'auditTable': totalAuditStockData > 5}">
    
                      <table mat-table [dataSource]="auditStockDisplayedItems">
                        <ng-container matColumnDef="sno">
                          <th mat-header-cell *matHeaderCellDef style="width: 6%">
                            S.NO
                          </th>
                          <td mat-cell *matCellDef="let row; let i=index;" class="text-center">
                            {{  calculateSerialNumberR(i) }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="storageLocation">
                          <th mat-header-cell *matHeaderCellDef>
                            storage Location
                          </th>
                          <td mat-cell *matCellDef="let row" class="text-center">
                            {{ row.storage_location }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="binLocation">
                          <th mat-header-cell *matHeaderCellDef>
                            Bin Location
                          </th>
                          <td mat-cell *matCellDef="let row" class="text-center">
                            {{ row.bin }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="materialCode">
                          <th mat-header-cell *matHeaderCellDef>
                            Material Code
                          </th>
                          <td mat-cell *matCellDef="let row" class="text-center">
                            {{ row.material_code }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="materialDescription">
                          <th mat-header-cell *matHeaderCellDef>
                            Material Description
                          </th>
                          <td mat-cell *matCellDef="let row" class="text-left">
                            {{ row.material_description }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="auditStatus">
                          <th mat-header-cell *matHeaderCellDef>
                            Audit Status
                          </th>
                          <td mat-cell *matCellDef="let row" class="text-center">
                            {{ row.auditStatus }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="closingQuantity">
                          <th mat-header-cell *matHeaderCellDef>
                            Closing Quantity
                          </th>
                          <td mat-cell *matCellDef="let row"  class="text-center">
                            {{ row.closing_quantity }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="physicalQuantity">
                          <th mat-header-cell *matHeaderCellDef>
                            Physical Quantity
                          </th>
                          <td mat-cell *matCellDef="let row" class="text-center">
                            {{ row.scanned_quantity }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="shortageQuantity">
                          <th mat-header-cell *matHeaderCellDef>
                            Shortage Quantity
                          </th>
                          <td mat-cell *matCellDef="let row" class="text-center">
                            {{ row.shortage_quantity }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="excessQuantity">
                          <th mat-header-cell *matHeaderCellDef>
                            Excess Quantity
                          </th>
                          <td mat-cell *matCellDef="let row" class="text-center">
                            {{ row.excess_quantity }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="unitPrice">
                          <th mat-header-cell *matHeaderCellDef>
                            Unit Price
                          </th>
                          <td mat-cell *matCellDef="let row" class="text-center">
                            {{ row.unit_price }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="closingValue">
                          <th mat-header-cell *matHeaderCellDef>
                            Closing Value
                          </th>
                          <td mat-cell *matCellDef="let row" class="text-right">
                            {{ row.closing_value }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="shortageValue">
                          <th mat-header-cell *matHeaderCellDef>
                            Shortage Value
                          </th>
                          <td mat-cell *matCellDef="let row" class="text-right">
                            {{ row.shortage_value }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="excessValue">
                          <th mat-header-cell *matHeaderCellDef>
                            Excess Value
                          </th>
                          <td mat-cell *matCellDef="let row" class="text-right">
                            {{ row.excess_value }}
                          </td>
                        </ng-container>
    
                        <tr mat-header-row *matHeaderRowDef="auditStockDisplayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: auditStockDisplayedColumns"></tr>
                      </table>
                      <div class="text-center noDataFound" *ngIf="noDataReport && !arcompanyName && !auditNameR">
                        <h4 class="text-secondary m-3" >
                         Please  Select the Company Name and Audit Details 
                        </h4>
                      </div>
                      <div class="text-center noDataFound" *ngIf="noDataReport && arcompanyName && !auditNameR">
                        <h4 class="text-secondary m-3" >
                         Please  Select the Audit Details 
                        </h4>
                      </div>
                      <div class="text-center noDataFound"  *ngIf="noDataReport && arcompanyName && auditNameR">
                        <h4 class="text-secondary m-3">
                          No Records Found
                        </h4>
                      </div>
                    </div>
                 
                  </div>
                 
                  <mat-paginator *ngIf="!noDataReport && totalAuditStockData > 5" [length]="totalAuditStockData" [pageSize]="pageSizeAuditlistReport" [pageSizeOptions]="[5, 10, 50, 100, 500, 1000]"
                    #Rpaginator (page)="onpageeventR($event)">
                  </mat-paginator>
                </div>
              </mat-card-content>
            </mat-card>
    
            <!-- <mat-card class="dashboard-card horizontal-scroll-card">
              <mat-card-content class="dashboard-card-content padding">
                
              </mat-card-content>
            </mat-card> -->
    
          </ng-template>
        </mat-tab>
  </mat-tab-group>
</div>

<ng-template #fileuploadmodel1>
  <div mat-dialog-title class="dialog-title d-flex justify-content-between">
      <h2>File Upload</h2>
      <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
          >
          <mat-icon>close</mat-icon>
      </button>
  </div>
  <div mat-dialog-content class="row matContent" [ngStyle]="{'overflow': (selectedfiles.length <= 0) ? ('hidden') : ('hidden')}">
      <div class="table-responsive mb-2" *ngIf="selectedfiles.length > 0">
          <table>
              <thead class="theaderclassbg">
                  <th class="textCenter">S.NO</th>
                  <th class="textLeft">File Name</th>
                  <th class="textCenter">Action</th>
              </thead>
              <tbody>
                  <tr *ngFor="let item of selectedfiles;let i=index;">
                      <td class="textCenter">{{i+1}}</td>
                      <td class="textLeft">{{item.name}}</td>
                      <td class="textCenter">
                          <button mat-icon-button class="deleteIcon"
                              (click)="deleteFileItem(i)">
                              <mat-icon>delete</mat-icon>
                          </button>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
      <button type="button" mat-raised-button class="attachfile attachFileBtn" color="secondary"
      (click)="autoFileUpload.click()" style="display: block;">
      Attach File&nbsp; <mat-icon
        class="attachFileIcon">file_upload</mat-icon></button>
    <div class="file">
      <input hidden class="form-control-file" name="file" type="file"
    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    (change)="uploadgifiles($event)" #autoFileUpload>
      <!-- <h6 class="mt-1">{{selectedFileName}}</h6> -->
    </div>
    <div class="mt-3">
      <p (click)="SampleFileDownload()" class="samplefile">Download Sample File</p>
    </div>
      <!-- <button [disabled]="initialdata" type="button" mat-raised-button class="attachfile attachFileBtn ml-1" color="secondary"
      (click)="autoFileUpload2.click()">
      Attach File&nbsp; <mat-icon class="attachFileIcon">file_upload</mat-icon></button>
  <div class="file">
       multiple
      <input hidden class="form-control-file" name="file" type="file" accept=".pdf" multiple
          (change)="uploadqcfiles($event)" #autoFileUpload2>
      <ng-container *ngIf=" fileUploadUrlsqc.length > 0">
      </ng-container> -->
  </div>       
 <br>
</ng-template>

<div class="table-responsive d-none" #TABLE>
  <table class="table table-bordered">
    <thead>
      <tr>
        <th>Plan</th>
        <th>SLoc</th>
        <th>Material</th>
        <th>Material Number</th>
        <th>Val. Type</th>
        <th>SAP Stock</th>
        <th>Physical Stock</th>
        <th>Diff</th>
        <th>BUn</th>
        <th>Total Value</th>
        <th>Crcy</th>
        <th>Bin</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>5310</td>
        <td>MS01</td>
        <td>2200100002</td>
        <td>Concrete Poles</td>
        <td>Count</td>
        <td>498.00</td>
        <td></td>
        <td></td>
        <td>Count</td>
        <td>35154.99</td>
        <td></td>
        <td>S1R01C</td>
      </tr>
      <tr>
        <td>5311</td>
        <td>MS02</td>
        <td>2200100001</td>
        <td>Concrete Bricks</td>
        <td>Count</td>
        <td>2725.00</td>
        <td></td>
        <td></td>
        <td>Count</td>
        <td>311245.6</td>
        <td></td>
        <td>S1R01C</td>
      </tr>
    </tbody>
  </table>
  </div>

  <ng-template #rejectmodel>
    <div mat-dialog-title>
      
    </div>
    <div mat-dialog-content >
      <p>Are you sure, you want to reject record?</p>
      <label>Reason<span class="mandetory">*</span></label>
      <input class="inputstyles" type="text" placeholder="Enter Reason" name="reason" [ngModel]="rejectModel.reason" 
      (ngModelChange)="rejectModel.reason=$event.trim()" 
      (input)="rejectModel.reason=$event.target.value.trim()"/>
    </div>
    <div mat-dialog-actions class="justify-content-center mt-3">
      <button type="submit" mat-button cdkFocusInitial mat-dialog-close class="matButton mr-2">Cancel</button>
      <button type="submit" mat-button cdkFocusInitial class="matButton" (click)="rejectData()" [disabled]="!rejectModel.reason">Reject</button>
    </div>
  </ng-template>

  <ng-template #approvemodel>
    <div mat-dialog-title>
      
    </div>
    <div mat-dialog-content >
      <p>Are you sure, you want to approve record?</p>
      <label>Reason</label>
      <input class="inputstyles" type="text" placeholder="Enter Reason" name="reason" [ngModel]="approveModel.reason" 
      (ngModelChange)="approveModel.reason=$event.trim()" 
      (input)="approveModel.reason=$event.target.value.trim()"/>
    </div>
    <div mat-dialog-actions class="justify-content-center mt-3">
      <button type="submit" mat-button cdkFocusInitial mat-dialog-close class="matButton mr-2">Cancel</button>
      <button type="submit" mat-button cdkFocusInitial class="matButton" (click)="approveData()">Approve</button>
    </div>
  </ng-template>